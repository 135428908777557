import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Collapse, Pagination } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from 'axios';

const MyReferrals = ({ user }) => {
    const [expanded, setExpanded] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [referrals, setReferrals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const referralsPerPage = 5;

    useEffect(() => {
        const fetchReferrals = async () => {
            if (user && user.firebaseUid) {
                try {
                    setLoading(true);
                    const response = await axios.get(`https://api.academy.marsreel.com/api/invitations/${user.firebaseUid}`);
                    console.log('Fetched Referrals:', response.data); // Add this to debug
                    setReferrals(response.data);
                } catch (error) {
                    console.error('Failed to fetch referrals:', error);
                    setError(true);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchReferrals();
    }, [user]);

    const handleExpandClick = (index) => {
        const newExpanded = [...expanded];
        newExpanded[index] = !newExpanded[index];
        setExpanded(newExpanded);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const indexOfLastReferral = currentPage * referralsPerPage;
    const indexOfFirstReferral = indexOfLastReferral - referralsPerPage;
    const currentReferrals = referrals.slice(indexOfFirstReferral, indexOfLastReferral);

    const renderReferralStages = (referral) => {
        const stages = [
            referral.dateSent ? `Invitation Sent on ${new Date(referral.dateSent).toLocaleDateString()}` : 'Invitation Pending',
            referral.dateJoined ? `Registration Complete on ${new Date(referral.dateJoined).toLocaleDateString()}` : 'Registration Pending',
            referral.firstSession ? `Attended 1st Session on ${new Date(referral.firstSession).toLocaleDateString()}` : 'First Session Pending',
            referral.firstPurchase ? `First Purchase on ${new Date(referral.firstPurchase).toLocaleDateString()}` : 'First Purchase Pending',
            referral.rewardStatus ? 'Credit Reward Issued' : 'Credit Reward Pending',
            referral.rewardIssued ? `${referral.rewardCredits || 0} Credits Issued on ${new Date(referral.rewardIssued).toLocaleDateString()}` : 'Credits Pending',
        ];

        return (
            <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginTop="1rem">
                {stages.map((stage, index) => (
                    <Box key={index} display="flex" alignItems="center" margin="0.5rem 1rem">
                        {stage.includes('Pending') ? (
                            <CheckCircleOutlineIcon size={20} style={{ color: '#12c099', marginRight: '0.5rem' }} />
                        ) : (
                            <CheckCircleIcon size={20} style={{ color: '#12c099', marginRight: '0.5rem' }} />
                        )}
                        <Typography variant="body2" style={{ color: '#12c099', fontSize: '0.8rem' }}>{stage}</Typography>
                    </Box>
                ))}
            </Box>
        );
    };


    return (
        <Box
            bgcolor="#202020"
            color="#fff"
            p={3}
            borderRadius={2}
            boxShadow={3}
            marginBottom="1rem"
        >
            <Typography variant="h6" align="left">
                My Referrals ({referrals.length || 0})
            </Typography>

            {loading ? (
                <Typography variant="body1" style={{ color: '#12c099' }} align="center">
                    Loading your referrals...
                </Typography>
            ) : error ? (
                <Typography variant="body1" style={{ color: 'red' }} align="center">
                    Failed to load referrals. Please try again later.
                </Typography>
            ) : referrals.length === 0 ? (
                <Typography variant="body1" style={{ color: '#fff' }} align="center">
                    You don’t have any referrals yet! Start inviting your friends to Mars Reel Academy and track their progress here.
                </Typography>
            ) : (
                <>
                    <List>
                        {currentReferrals.map((referral, index) => (
                            <React.Fragment key={index}>
                                <ListItem button onClick={() => handleExpandClick(index)}>
                                    <ListItemText
                                        primary={referral.name || 'Unknown'}
                                        secondary={<span style={{ color: '#12c099' }}>Status: {referral.dateJoined ? 'Registered' : 'Pending'}</span>}
                                        style={{ textAlign: 'left' }}
                                    />
                                    {expanded[index] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                    <Box ml={3}>
                                        {renderReferralStages(referral)}
                                    </Box>
                                </Collapse>
                            </React.Fragment>
                        ))}
                    </List>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Pagination
                            count={Math.ceil(referrals.length / referralsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default MyReferrals;
