import React from 'react';
import { Container, Typography } from '@mui/material';

const AccountManagement = () => {
    return (
        <Container style={{ paddingTop: '6rem', paddingBottom: '2rem' }}>
            <Typography variant="h4">Account Management</Typography>
            <Typography variant="body1" paragraph>
                This is the account management page. Learn how to update your profile, change your password, and manage other account settings here.
            </Typography>
        </Container>
    );
};

export default AccountManagement;
