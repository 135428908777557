import React from 'react';
import { Box, Link, Grid } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

const AppStoreLinks = () => {
    return (
        <Box marginTop="3rem">
            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                {/* iOS App Store */}
                <Grid
                    item
                    xs={12} sm={6} md={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Link href="https://apps.apple.com/us/app/mars-reel-academy/id6680150371" target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                        <AppleIcon sx={{ fontSize: 50, marginRight: '10px' }} />
                        <Box component="span" sx={{ fontSize: 18, fontWeight: 'bold' }}>App Store</Box>
                    </Link>
                </Grid>

                {/* Google Play Store */}
                <Grid
                    item
                    xs={12} sm={6} md={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Link href="https://play.google.com/store/apps/details?id=com.marsdeyo.marsreelacademy" target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                        <AndroidIcon sx={{ fontSize: 50, marginRight: '10px' }} />
                        <Box component="span" sx={{ fontSize: 18, fontWeight: 'bold' }}>Google Play</Box>
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AppStoreLinks;
