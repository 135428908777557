import React from 'react';
import { Container, Typography, Card, CardContent, Grid, Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    paddingTop: '6rem',
    paddingBottom: '2rem',
    minHeight: '100vh',
});

const StyledCard = styled(Card)({
    marginBottom: '1rem',
});

const SubscriptionPlans = () => {
    return (
        <StyledContainer maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Subscription Plans
            </Typography>
            <Typography variant="body1" paragraph>
                Explore our available subscription plans below and choose the one that best fits your needs.
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">Basic Plan</Typography>
                            <Typography variant="body2">
                                $9.99/month - Access to basic features and content.
                            </Typography>
                            <Button variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }}>
                                Choose Basic Plan
                            </Button>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">Premium Plan</Typography>
                            <Typography variant="body2">
                                $19.99/month - Includes premium features and exclusive content.
                            </Typography>
                            <Button variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }}>
                                Choose Premium Plan
                            </Button>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">Pro Plan</Typography>
                            <Typography variant="body2">
                                $29.99/month - Unlock all features, priority support, and more.
                            </Typography>
                            <Button variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }}>
                                Choose Pro Plan
                            </Button>
                        </CardContent>
                    </StyledCard>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};

export default SubscriptionPlans;
