import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Paper } from '@mui/material';
import { styled } from '@mui/system';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import axios from 'axios';

const InfoBox = styled(Paper)({
    backgroundColor: '#202020',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'left',
    color: '#fff',
    marginBottom: '1rem',
});

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || 'https://api.academy.marsreel.com';

const InviteFriends = ({ firstName, lastName, referralCode, firebaseUid }) => {
    const [inviteFriends, setInviteFriends] = useState([{ contact: '', name: '' }]);

    const handleInviteChange = (index, field, value) => {
        const updatedFriends = [...inviteFriends];
        updatedFriends[index][field] = value;
        setInviteFriends(updatedFriends);
    };

    const handleAddFriend = () => {
        setInviteFriends([...inviteFriends, { contact: '', name: '' }]);
    };

    const handleRemoveFriend = (index) => {
        const updatedFriends = inviteFriends.filter((_, i) => i !== index);
        setInviteFriends(updatedFriends);
    };

    const handleInvite = async () => {
        try {
            const formattedInvites = inviteFriends.map(friend => ({
                contact: friend.contact,
                name: friend.name,
                type: friend.contact.includes('@') ? 'email' : 'phone',
            }));

            const response = await axios.post('/api/invitations/invite', {
                invitations: formattedInvites,
                firstName,
                lastName,
                referralCode,
                referrerID: firebaseUid, // Use the `firebaseUid` passed from the parent
            });

            console.log('Invitations response:', response);
            alert('Invitations sent successfully!');
        } catch (error) {
            console.error('Failed to send invitations:', error);
            alert('Failed to send invitations. Please try again later.');
        }
    };



    return (
        <InfoBox>
            <Typography variant="h6">Invite Friends</Typography>
            <Typography variant="body2" style={{ marginTop: '0.5rem' }}>
                Invite your friends to join Mars Reel Academy. For every referral you will earn 2 free credits when they join their first session!
            </Typography>
            {inviteFriends.map((friend, index) => (
                <Box key={index} display="flex" flexDirection="column" marginTop="0.5rem">
                    <TextField
                        label="Recipient's Name"
                        value={friend.name}
                        onChange={(e) => handleInviteChange(index, 'name', e.target.value)}
                        fullWidth
                        variant="filled"
                        margin="dense"
                        InputLabelProps={{
                            style: { color: 'rgba(255, 255, 255, 0.6)' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label="Enter email or phone number"
                        value={friend.contact}
                        onChange={(e) => handleInviteChange(index, 'contact', e.target.value)}
                        fullWidth
                        variant="filled"
                        margin="dense"
                        InputLabelProps={{
                            style: { color: 'rgba(255, 255, 255, 0.6)' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    {inviteFriends.length > 1 && (
                        <IconButton onClick={() => handleRemoveFriend(index)} style={{ marginTop: '0.5rem', color: '#ff1744' }}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    )}
                </Box>
            ))}
            <Button onClick={handleAddFriend} style={{ marginTop: '1rem', color: '#12c099' }}>
                Add Another Friend
            </Button>
            <Button variant="contained" onClick={handleInvite} style={{ marginTop: '0.5rem', backgroundColor: '#12c099' }}>
                Send Invites
            </Button>
        </InfoBox>
    );
};

export default InviteFriends;
