import React from 'react';
import { Container, Typography, Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';


const StyledContainer = styled(Container)({
    paddingTop: '6rem', // Adjust this value to ensure content is not under the navigation bar
    paddingBottom: '2rem',
    minHeight: '100vh',
});

const StyledCard = styled(Card)({
    marginBottom: '1rem',
    height: '100%',
    width: '100%',
});

const HelpCenter = () => {
    return (
        <StyledContainer maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Help Center
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">
                                <Link to="/account-management" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    Account Management
                                </Link>
                            </Typography>
                            <Typography variant="body2">
                                Learn how to update your profile, change your password, and manage other account settings.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">
                                <Link to="/payment-issues" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    Payment Issues
                                </Link>
                            </Typography>
                            <Typography variant="body2">
                                Get help with updating payment methods, understanding billing cycles, and resolving payment issues.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">
                                <Link to="/technical-support" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    Technical Support
                                </Link>
                            </Typography>
                            <Typography variant="body2">
                                Tips for troubleshooting common technical issues and how to contact our technical support team for further assistance.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">
                                <Link to="/subscription-plans" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    Subscription Plans
                                </Link>
                            </Typography>
                            <Typography variant="body2">
                                Information about our subscription plans, including the benefits and how to switch between plans.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">
                                <Link to="/using-credits" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    Using Credits
                                </Link>
                            </Typography>
                            <Typography variant="body2">
                                Learn how to use credits for accessing various activities and events.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">
                                <Link to="/contact-us" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    Contact Us
                                </Link>
                            </Typography>
                            <Typography variant="body2">
                                Find out how to contact our support team for additional assistance.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};

export default HelpCenter;
