import React, { useState, useEffect } from 'react';
import { Box, Typography, Checkbox, Button, IconButton, Collapse, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const MySports = ({ userSports, setUserSports, user }) => {
    const [availableSports, setAvailableSports] = useState([]);
    const [open, setOpen] = useState(false);
    const [sportToAddOrRemove, setSportToAddOrRemove] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    // Function to toggle the sports list
    const handleToggle = () => {
        setOpen(!open);
    };

    // Fetch all available sports
    useEffect(() => {
        const fetchSports = async () => {
            try {
                const response = await axios.get('https://api.academy.marsreel.com/api/sports');
                setAvailableSports(response.data.sports);
            } catch (error) {
                console.error('Failed to fetch sports:', error);
            }
        };

        fetchSports();
    }, []);

    // Fetch user's sports (if not passed via props)
    useEffect(() => {
        const fetchUserSports = async () => {
            if (user && user.firebaseUid) {
                try {
                    const response = await axios.get(`https://api.academy.marsreel.com/api/users/details-only/${user.firebaseUid}`);
                    setUserSports(response.data.user.sports || []);
                } catch (error) {
                    console.error('Failed to fetch user sports:', error);
                }
            }
        };

        fetchUserSports();
    }, [user, setUserSports]);

    // Handle modal confirmation for sports selection
    const handleConfirmSelection = (sport, isRemoving) => {
        setSportToAddOrRemove(sport);
        setIsRemoving(isRemoving);
        setConfirmDialogOpen(true);
    };

    // Confirm modal actions for adding/removing a sport
    const handleConfirmAction = () => {
        if (isRemoving) {
            setUserSports((prevSports) => prevSports.filter((s) => s !== sportToAddOrRemove));
        } else {
            setUserSports((prevSports) => [...prevSports, sportToAddOrRemove]);
        }
        setConfirmDialogOpen(false);
    };

    // Prevent unchecking all sports
    const toggleSportSelection = (sport) => {
        if (userSports.includes(sport)) {
            if (userSports.length === 1) {
                alert('You must have at least one sport selected.');
                return;
            }
            handleConfirmSelection(sport, true);
        } else {
            handleConfirmSelection(sport, false);
        }
    };

    // Update sports on server
    const updateSportsOnServer = async () => {
        try {
            const firebaseUid = user.firebaseUid; // Assuming you have the user's Firebase UID available in the user prop
            await axios.put(`https://api.academy.marsreel.com/api/users/update-sports/${firebaseUid}`, {
                sports: userSports,
            });
            alert('Sports updated successfully');
        } catch (error) {
            console.error('Failed to update sports:', error);
            alert('Failed to update sports');
        }
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">My Sports</Typography>
                <IconButton onClick={handleToggle}>
                    {open ? <ExpandLess style={{ color: '#fff' }} /> : <ExpandMore style={{ color: '#fff' }} />}
                </IconButton>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box display="flex" flexDirection="column" mt={2}>
                    {availableSports.map((sport) => (
                        <FormControlLabel
                            key={sport.name}
                            control={
                                <Checkbox
                                    checked={userSports.includes(sport.name)}
                                    onChange={() => toggleSportSelection(sport.name)}
                                    style={{ color: '#12c099' }}
                                />
                            }
                            label={sport.name}
                            style={{ color: '#fff' }}
                        />
                    ))}
                </Box>
                <Button
                    variant="contained"
                    style={{ marginTop: '0.5rem', backgroundColor: '#12c099' }}
                    onClick={updateSportsOnServer}
                >
                    Save
                </Button>
            </Collapse>

            {/* Confirmation Dialog */}
            <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
            >
                <DialogTitle>
                    {isRemoving ? 'Confirm Removal' : 'Confirm Addition'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {isRemoving
                            ? `Are you sure you want to remove ${sportToAddOrRemove} from your sports list?`
                            : `Are you sure you want to add ${sportToAddOrRemove} to your sports list?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmAction} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default MySports;
