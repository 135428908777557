// components/EngineeringAndDataScience.js
import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import { styled } from '@mui/system';

const Root = styled('div')({
    backgroundColor: '#141414',
    color: '#e0e0e0',
    minHeight: '100vh',
    padding: '3rem 1.5rem',
    display: 'flex',
    justifyContent: 'center',
});

const Section = styled(Box)({
    marginBottom: '3rem',
});

const HeaderContainer = styled(Box)({
    marginBottom: '4rem',
    marginTop: '2rem', // Added space above the header
    textAlign: 'center',
});

const Header = styled(Typography)({
    fontSize: '2.5rem',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: '2rem',
    '@media (max-width: 600px)': {
        fontSize: '2rem',
    },
});

const SubHeader = styled(Typography)({
    marginBottom: '3rem',
    fontSize: '1rem',
    textAlign: 'center',
    color: '#b0b0b0',
    '@media (max-width: 600px)': {
        fontSize: '0.9rem',
    },
});

const SectionTitle = styled(Typography)({
    fontSize: '1.25rem',
    fontWeight: '500',
    marginBottom: '1rem',
    color: '#ffffff',
});

const SectionContent = styled(Typography)({
    fontSize: '0.9rem',
    lineHeight: '1.5rem',
    color: '#c0c0c0',
});

const DividerLine = styled(Divider)({
    backgroundColor: '#303030',
    margin: '2rem 0',
});

const SummaryParagraph = styled(Typography)({
    marginTop: '4rem',
    fontSize: '0.9rem',
    textAlign: 'left',
    color: '#c0c0c0',
    '@media (max-width: 600px)': {
        fontSize: '0.8rem',
    },
});

const ComingSoonHeader = styled(Typography)({
    fontSize: '1.5rem',
    fontWeight: '500',
    marginTop: '4rem',
    textAlign: 'center',
    color: '#ffffff',
});

const EngineeringAndDataScience = () => {
    return (
        <Root>
            <Container maxWidth="md">
                <HeaderContainer>
                    <Header>Introducing SPACE v1</Header>
                    <SubHeader>Pioneering Youth Sports & Culture Through Proprietary Technology</SubHeader>
                </HeaderContainer>

                <Section>
                    <SectionTitle>What is SPACE?</SectionTitle>
                    <SectionContent>
                        What is SPACE?

                        SPACE is Mars Reel®’s proprietary technology platform—a unified ecosystem designed to handle the complex challenges of modern youth sports, media, and advertising. It acts as the backbone of our operations, integrating advanced AI, cloud-native infrastructure, predictive analytics, and scalable logistics.

                        From content creation to event management, and advertising innovation, SPACE is our operating system for the challenge of driving innovation in youth culture.
                    </SectionContent>
                </Section>

                <DividerLine />

                <Section>
                    <SectionTitle>Artificial Intelligence & Natural Language Processing</SectionTitle>
                    <SectionContent>
                        SPACE v1 integrates AI and NLP technologies, leveraging frameworks and services from OpenAI and Google to drive dynamic and real time personalization. These capabilities ensure that our platform adapts to individual needs while broadening access to our experiences.
                    </SectionContent>
                </Section>

                <DividerLine />

                <Section>
                    <SectionTitle>Cloud-Native Architecture & Scalable Infrastructure</SectionTitle>
                    <SectionContent>
                        Built on AWS’s cloud-native services and Heroku, SPACE v1 is designed for scalability and resilience. This robust infrastructure supports global deployments, ensuring high availability and consistent performance as our platform grows and evolves.
                    </SectionContent>
                </Section>

                <DividerLine />

                <Section>
                    <SectionTitle>Data Science & Predictive Analytics</SectionTitle>
                    <SectionContent>
                        At the core of SPACE v1 is the integration of advanced data science techniques, enabling us to extract actionable insights from vast datasets. These insights are being used to build tools that will fuel strategic decision-making, optimize user engagement, and drive the ongoing evolution of MarsReel® Academy’s platform.
                    </SectionContent>
                </Section>

                <DividerLine />

                <Section>
                    <SectionTitle>Microservices Architecture</SectionTitle>
                    <SectionContent>
                        Modular, Dockerized, and Interoperable. SPACE v1 is built with a modular, microservices-based architecture that ensures interoperability across the entire platform. Each component is Dockerized and deployed to Heroku container, providing isolated, consistent, and easily scalable services across various environments.

                        SPACE allows MarsReel® Academy to integrate seamlessly and adapt to evolving needs. As a result, the platform remains robust, reliable, and ready to meet future challenges while upholding the highest standards of software development.
                    </SectionContent>
                </Section>

                <DividerLine />

                <Section>
                    <SectionTitle>Secure QR Code Check-In & Verification Systems</SectionTitle>
                    <SectionContent>
                        Powered by SPACE v1, MarsReel® Academy’s check-in system leverages secure QR code technology to enable efficient and accurate participant verification. Additionally, we implement CORS (Cross-Origin Resource Sharing) to ensure secure and seamless communication between our web applications and servers, enabling safe access to the necessary resources across different domains.
                    </SectionContent>
                </Section>

                <DividerLine />

                <ComingSoonHeader>The Vision for SPACE</ComingSoonHeader>

                <SummaryParagraph>
                    SPACE v1 is just the beginning. SPACE is more than technology—it’s an evolving system built to scale globally and innovate across youth sports, media, and culture. With future iterations, SPACE will continue to push the boundaries of automation, intelligence, and engagement, setting a new standard for youth sports and culture.
                </SummaryParagraph>
            </Container>
        </Root>
    );
};

export default EngineeringAndDataScience;
