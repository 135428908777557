import React from 'react';
import { Typography, Container, AppBar, Toolbar, Button } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './components/PaymentForm';
import { useNavigate } from 'react-router-dom';

// Load Stripe using the public key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Root = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#141414',
});

const StyledAppBar = styled(AppBar)({
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
    borderBottom: '1px solid #e6e6e6',
    zIndex: 10,
    position: 'fixed',
    top: 0,
    width: '100%',
});

const Logo = styled('img')({
    flexGrow: 1,
    height: 'auto',
    width: '20%',
    maxWidth: '100px',
    minWidth: '50px',
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    zIndex: 2,
    marginTop: '4rem',
});

const FormContainer = styled(Container)({
    backgroundColor: 'rgba(51, 51, 51, 0.8)',
    padding: '1rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    marginTop: '1rem',
    position: 'relative',
    zIndex: 5, // Ensure form is above other elements
});

const StepIndicator = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0.5rem',
    zIndex: 2,
});

const SkipButton = styled(Button)({
    marginTop: '1rem',
    color: '#fff',
    border: '1px solid #fff',
    zIndex: 1, // Ensure it does not overlap with the credit card form
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
});

const SetupPayment = () => {
    const navigate = useNavigate();

    const handleSkip = () => {
        navigate('/start-training');
    };

    return (
        <Root>
            <StyledAppBar position="fixed">
                <Toolbar>
                    <Logo src="https://marsreel.com/wp-content/themes/marsreel/images/shared/marsreel-logo.png" alt="MarsReel" />
                </Toolbar>
            </StyledAppBar>

            <StyledContainer maxWidth="sm">
                <StepIndicator>
                    <CheckCircleIcon style={{ marginRight: '0.5rem', color: '#12c099', fontSize: '2rem' }} />
                    <Typography variant="h5" style={{ fontSize: '2rem' }}>Step 3 of 3</Typography>
                </StepIndicator>
                <Typography variant="h4" gutterBottom>
                    Set Up Payment
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Please add a credit or debit card to continue. Your card will not be charged at this stage.
                </Typography>

                <FormContainer>
                    <Elements stripe={stripePromise}>
                        <PaymentForm />
                    </Elements>
                </FormContainer>

                <SkipButton variant="outlined" onClick={handleSkip}>
                    Skip for Now
                </SkipButton>
            </StyledContainer>
        </Root>
    );
};

export default SetupPayment;
