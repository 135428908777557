import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Button, TextField, Typography, Container, AppBar, Toolbar, Checkbox,
    FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, Box,
    MenuItem, Select, InputLabel, FormControl,
} from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import SignatureCanvas from 'react-signature-canvas';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Custom Modal for selecting sports
const SelectSportsModal = ({ open, onClose, availableSports, selectedSports, onSportToggle }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Select Sports</DialogTitle>
        <DialogContent>
            <Box display="flex" flexDirection="column">
                {availableSports.map((sport) => (
                    <FormControlLabel
                        key={sport.name}
                        control={
                            <Checkbox
                                checked={selectedSports.includes(sport.name)}
                                onChange={() => onSportToggle(sport.name)}
                            />
                        }
                        label={sport.name}
                    />
                ))}
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} style={{ color: '#12c099' }}>Done</Button>
        </DialogActions>
    </Dialog>
);

const Root = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#141414',
});

const StyledAppBar = styled(AppBar)({
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
    borderBottom: '1px solid #e6e6e6',
    zIndex: 10,
    position: 'fixed',
    top: 0,
    width: '100%',
});

const Logo = styled('img')({
    flexGrow: 1,
    height: 'auto',
    width: '20%',
    maxWidth: '100px',
    minWidth: '50px',
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    zIndex: 2,
    marginTop: '4rem',
});

const FormContainer = styled(Container)({
    backgroundColor: 'rgba(51, 51, 51, 0.8)',
    padding: '1rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    marginTop: '1rem',
});

const StepIndicator = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0.5rem',
    zIndex: 2,
});

const PlanIndicator = styled('div')({
    marginBottom: '1rem',
    zIndex: 2,
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    marginTop: '0.5rem',
});

const StyledTextField = styled(TextField)({
    marginBottom: '0.5rem',
});

const ConsentLabel = styled(Typography)({
    fontSize: '0.875rem',
    color: '#fff',
});

const WaiverContent = styled(Typography)({
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    marginBottom: '1rem',
});

const CreateAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedPlan } = location.state || { selectedPlan: 'No plan selected' };

    const [sportsModalOpen, setSportsModalOpen] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        birthdate: '',
        gender: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        consent: false,
        waiverConsent: false,
        waiverDialogOpen: false,
        waiverSigned: false,
        waiverName: '',
        authorizedSignatureName: '',
        referralCode: '',
        referralError: '',
        emailError: '',
        sports: [], // New field for selected sports
    });

    const [availableSports, setAvailableSports] = useState([]);

    useEffect(() => {
        const fetchSports = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/sports`);
                setAvailableSports(response.data.sports);
            } catch (error) {
                console.error('Failed to fetch sports:', error);
            }
        };

        fetchSports();
    }, []);

    // Function to toggle the selection of a sport
    const toggleSportSelection = (sport) => {
        setFormData((prevState) => ({
            ...prevState,
            sports: prevState.sports.includes(sport)
                ? prevState.sports.filter((selectedSport) => selectedSport !== sport)
                : [...prevState.sports, sport],
        }));
    };

    const [signatureData, setSignatureData] = useState(null);
    const signaturePadRef = useRef(null);

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSignatureEnd = () => {
        if (signaturePadRef.current) {
            setSignatureData(signaturePadRef.current.toDataURL('image/png'));
            console.log('Signature completed');
        }
    };

    const geocodeAddress = async (address) => {
        try {
            const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`
            );
            if (response.data.status === 'OK') {
                const location = response.data.results[0];
                const components = location.address_components;
                const city = components.find((c) => c.types.includes('locality'))?.long_name || '';
                const state = components.find((c) => c.types.includes('administrative_area_level_1'))?.short_name || '';
                const zip = components.find((c) => c.types.includes('postal_code'))?.long_name || '';

                // Normalize city name to capitalize only the first letter
                const normalizedCity = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();

                return { city: normalizedCity, state, zip };
            } else {
                throw new Error('Failed to geocode address');
            }
        } catch (error) {
            console.error('Geocoding error:', error);
            throw new Error('Failed to geocode address');
        }
    };

    const calculateAge = (birthdate) => {
        const birthDate = new Date(birthdate);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const validateReferralCode = async (code) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/validate-referral-code`, { referralCode: code });
            return response.data.isValid;
        } catch (error) {
            console.error('Error validating referral code:', error);
            return false;
        }
    };

    const handleOpenWaiverDialog = () => {
        setFormData({ ...formData, waiverDialogOpen: true });
    };

    const handleCloseWaiverDialog = () => {
        setFormData({ ...formData, waiverDialogOpen: false });
    };

    const handleWaiverConsent = () => {
        if (!formData.waiverName) {
            alert('Please enter your name to confirm.');
            return;
        }
        setFormData({ ...formData, waiverSigned: true, waiverDialogOpen: false });
    };

    const handleRef = (ref) => {
        signaturePadRef.current = ref;
        if (ref) {
            console.log('Signature pad is ready');
        } else {
            console.error('Signature pad is not available');
        }
    };

    const handleSubmit = async () => {
        setFormData({ ...formData, emailError: '' });

        // Check for required consents and waivers
        if (!formData.consent) {
            alert('You must agree to receive messages.');
            return;
        }

        if (!formData.waiverSigned || !formData.waiverConsent) {
            alert('You must agree to the waiver.');
            return;
        }

        // Validate referral code if provided
        if (formData.referralCode && !(await validateReferralCode(formData.referralCode))) {
            setFormData({ ...formData, referralError: 'Invalid referral code' });
            return;
        }

        let firebaseUid = null;
        let userData = {};

        try {
            console.log('Starting form submission...');

            // Format the phone number using libphonenumber-js
            const phoneNumber = parsePhoneNumberFromString(formData.phone, 'US');
            const formattedPhone = phoneNumber ? phoneNumber.format('E.164') : null;

            if (!formattedPhone) {
                throw new Error('Invalid phone number format');
            }

            console.log('Formatted Phone:', formattedPhone);

            // Step 1: Authenticate the user with Firebase
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            firebaseUid = userCredential.user.uid;
            console.log('User authenticated with Firebase:', firebaseUid);

            // Step 2: Prepare the user data
            userData = {
                firebaseUid,
                email: formData.email,
                firstName: formData.firstName,
                lastName: formData.lastName,
                phone: formattedPhone,
                birthday: formData.birthdate,
                gender: formData.gender,
                city: formData.city,
                state: formData.state,
                password: formData.password,
                zipCode: formData.zipCode,
                country: formData.country,
                sports: formData.sports,
                planDetails: {
                    type: selectedPlan === 'Recurring' ? 'subscription' : 'pay_as_you_go',
                    isRecurring: selectedPlan === 'Recurring',
                    pricePerCredit: 37.5,
                    monthlyFee: selectedPlan === 'Recurring' ? 250 : null,
                    creditsPerMonth: selectedPlan === 'Recurring' ? 8 : null,
                },
                injuryWaiverSigned: formData.waiverSigned,
                publicityWaiverSigned: formData.waiverSigned,
                referralCode: formData.referralCode,
            };

            console.log('Form submitted with:', userData);

            // Step 3: Send InitiateCheckout Event to Facebook (Non-critical)
            try {
                const pixelId = '586371507254917'; // Replace with your Pixel ID
                const accessToken = 'EAAIckjdekZBgBO3...'; // Replace with your access token
                const apiUrl = `https://graph.facebook.com/v21.0/${pixelId}/events`;

                const eventData = {
                    data: [
                        {
                            event_name: 'InitiateCheckout',
                            event_time: Math.floor(Date.now() / 1000), // Current Unix timestamp
                            event_source_url: window.location.href, // Current page URL
                            action_source: 'website',
                            user_data: {
                                em: formData.email, // Email (hashed internally by Facebook's API)
                                ph: formattedPhone, // Phone number
                                fn: formData.firstName.toLowerCase(), // First Name
                                ln: formData.lastName.toLowerCase(), // Last Name
                                ge: formData.gender.toLowerCase(), // Gender
                                db: formData.birthdate, // Date of Birth
                                ct: formData.city.toLowerCase(), // City
                                st: formData.state.toLowerCase(), // State
                                zp: formData.zipCode, // Zip Code
                                country: formData.country.toLowerCase(), // Country
                                client_user_agent: navigator.userAgent, // Browser's User-Agent
                            },
                        },
                    ],
                };

                await axios.post(apiUrl, eventData, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                console.log('InitiateCheckout Event Sent Successfully to Facebook');
            } catch (fbError) {
                console.error('Facebook API failed:', fbError.message);
                // Log this error for debugging but do not interrupt the flow
            }

            // Step 4: Send the user data to the backend
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/create-account`, userData);

            if (response.status === 201) {
                console.log('Account creation successful:', response.data);

                // Step 5: Generate and send PDF if signature data is available
                if (signatureData) {
                    console.log('Signature data being sent:', signatureData);

                    const generatePdfResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/pdf/generate`, {
                        userName: `${formData.firstName} ${formData.lastName}`,
                        email: formData.email,
                        participantName: formData.waiverName,
                        authorizedSignatureName: formData.authorizedSignatureName || formData.waiverName,
                        authorizedSignature: signatureData,
                    });

                    console.log('PDF generation response:', generatePdfResponse.data);
                    alert('Your account has been created. PDF generation has started and you will receive an email with the attached waiver shortly.');
                } else {
                    console.error('Signature pad is not available');
                    alert('Signature pad is not available');
                }

                // Step 6: Navigate to the setup-payment page
                navigate(`/setup-payment`, { state: { selectedPlan, user: response.data.user } });
            } else {
                console.error('Account creation failed:', response.data);
                alert(`Account creation failed: ${response.data.message}`);
                throw new Error('Backend user creation failed');
            }
        } catch (error) {
            console.error('Account creation failed:', error);

            // Check for specific location error and redirect to waitlist if found
            if (error.response && error.response.status === 400 && error.response.data.error === 'Location not covered') {
                console.log('Location not covered, deleting incomplete Firebase account and redirecting to waitlist');

                // Delete Firebase user if it was created
                if (firebaseUid) {
                    try {
                        await auth.currentUser.delete();
                        console.log('Incomplete Firebase account deleted');
                    } catch (deleteError) {
                        console.error('Failed to delete Firebase user:', deleteError);
                    }
                }

                navigate('/waitlist', { state: { cityName: formData.city, waitlistData: userData } });
                return; // Stop further execution
            }

            // Only delete the Firebase user if the error is not related to the backend user creation being successful
            if (firebaseUid && !(error.response && error.response.status === 201)) {
                try {
                    await auth.currentUser.delete();
                    console.log('Incomplete Firebase account deleted');
                } catch (deleteError) {
                    console.error('Failed to delete Firebase user:', deleteError);
                }
            }

            if (error.code === 'auth/email-already-in-use') {
                setFormData({ ...formData, emailError: 'This email is already registered.' });
            } else if (error.message.includes('IndexedDB')) {
                alert('There was a problem accessing the database. Please try again.');
            } else {
                alert(`Account creation failed: ${error.message}`);
            }
        }
    };



    return (
        <Root>
            <StyledAppBar position="fixed">
                <Toolbar>
                    <Logo src="https://marsreel.com/wp-content/themes/marsreel/images/shared/marsreel-logo.png" alt="MarsReel" />
                </Toolbar>
            </StyledAppBar>

            <StyledContainer maxWidth="sm">
                <StepIndicator>
                    <CheckCircleIcon style={{ marginRight: '0.5rem', color: '#12c099', fontSize: '2rem' }} />
                    <Typography variant="h5" style={{ fontSize: '2rem' }}>Step 2 of 3</Typography>
                </StepIndicator>
                <PlanIndicator>
                    <Typography variant="h6" style={{ fontSize: '1.2rem', color: '#12c099' }}>
                        Selected Plan: {selectedPlan === 'Recurring' ? 'Recurring' : 'Pay As You Go'}
                    </Typography>
                </PlanIndicator>
                <Typography variant="h4" gutterBottom>
                    Create an Account
                </Typography>
                <FormContainer>
                    <StyledTextField
                        label="First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                        error={!!formData.emailError}
                        helperText={formData.emailError}
                    />
                    <StyledTextField
                        label="Phone Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="Create a password"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="Birthdate"
                        name="birthdate"
                        type="date"
                        value={formData.birthdate}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                            shrink: true,
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
                        <Button variant="outlined" style={{ color: '#12c099' }} onClick={() => setSportsModalOpen(true)}>
                            Select Sports ({formData.sports.length})
                        </Button>
                        <Typography variant="body2" style={{ color: '#fff' }}>
                            {formData.sports.join(', ') || 'No sports selected'}
                        </Typography>
                    </Box>

                    <SelectSportsModal
                        open={sportsModalOpen}
                        onClose={() => setSportsModalOpen(false)}
                        availableSports={availableSports}
                        selectedSports={formData.sports}
                        onSportToggle={toggleSportSelection}
                    />


                    <FormControl fullWidth variant="filled" margin="dense">
                        <InputLabel style={{ color: '#fff' }}>Gender</InputLabel>
                        <Select
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                            style={{ color: '#fff' }}
                        >
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="non-binary">Non-Binary</MenuItem>
                            <MenuItem value="prefer not to say">Prefer Not to Say</MenuItem>
                        </Select>
                    </FormControl>
                    <StyledTextField
                        label="Street"
                        name="street"
                        value={formData.street}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="City"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="State"
                        name="state"
                        value={formData.state}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="Zip Code"
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="Country"
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="Referral Code (optional)"
                        name="referralCode"
                        value={formData.referralCode}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                        error={!!formData.referralError}
                        helperText={formData.referralError}
                    />
                    <FormControlLabel
                        control={<Checkbox name="consent" checked={formData.consent} onChange={handleInputChange} />}
                        label={<ConsentLabel>I agree to receive messages about my account and service updates.</ConsentLabel>}
                        style={{ color: '#fff' }}
                    />
                    <FormControlLabel
                        control={<Checkbox name="waiverConsent" checked={formData.waiverConsent} onChange={handleInputChange} disabled={!formData.waiverSigned} />}
                        label={<ConsentLabel>I agree to the injury and publicity waiver.</ConsentLabel>}
                        style={{ color: '#fff' }}
                    />
                    <Box>
                        <Button variant="text" style={{ color: '#12c099' }} onClick={handleOpenWaiverDialog}>
                            Read Waiver
                        </Button>
                    </Box>
                    <ActionButton fullWidth variant="contained" onClick={handleSubmit}>
                        Next
                    </ActionButton>
                </FormContainer>
            </StyledContainer>

            <Dialog open={formData.waiverDialogOpen} onClose={handleCloseWaiverDialog} maxWidth="md" fullWidth>
                <DialogTitle>Injury and Publicity Waiver</DialogTitle>
                <DialogContent>
                    <WaiverContent variant="body1">
                        {`I hereby assume all of the risks of participating in Mars Reel Academy training sessions, including by way of example and not limitation, any risks that may arise from negligence or carelessness on the part of the persons or entities being released, from dangerous or defective equipment or property owned, maintained or controlled by them, or because of their possible liability without fault. I certify that I am physically fit and have not been advised to not participate by a qualified medical professional. I certify that there are no health-related reasons or problems which preclude my participation in Mars Reel Academy. I acknowledge that this Accident Waiver and Release of Liability Form will be used by Mars Reel Media Corp. in which I may participate and that it will govern my actions and responsibilities at Mars Reel Academy training sessions.

In consideration of my application and permitting me to participate in Mars Reel Academy, I hereby take action for myself, my executors, administrators, heirs, next of kin, successors, and assigns as follows: (A) I WAIVE, RELEASE, AND DISCHARGE from any and all liability, including but not limited to, liability arising from the negligence or fault of the entities or persons released, for my death, disability, personal injury, property damage, property theft, or actions of any kind which may hereafter occur to me including my traveling to and from Mars Reel Academy sessions. THE FOLLOWING ENTITIES OR PERSONS: Mars Reel Media Corporation, and/or their subsidiaries, directors, officers, employees, coaches, trainers, agents, representatives or volunteers. (B) I INDEMNIFY, HOLD HARMLESS, AND PROMISE NOT TO SUE the entities or persons mentioned in this paragraph from any and all liabilities or claims made as a result of participation in Mars Reel Academy, whether caused by negligence or otherwise. I acknowledge that Mars Reel Academy may carry with it the potential for death, serious injury, and personal loss. The risks may include, but are not limited to, those caused by terrain, facilities, temperature, weather, condition of participants, equipment, vehicular traffic, actions of other people including, but not limited to, participants, volunteers, spectators, coaches, and lack of hydration.

I consent and agree that Mars Reel Media Corporation and/or their employees, directors, officers, coaches, agents, representatives or volunteers may take photographs or digital recordings of me as a participant during this event and use these in any and all media for training or promotional purposes. I further consent that my identity may be revealed therein or by description text or commentary. I waive any rights, claims or interest and I understand that there will be no financial or other remuneration. This accident waiver, release of liability and image release shall be construed broadly to provide a release and waiver to the maximum extent permissible under applicable law. 

I CERTIFY THAT I HAVE READ THIS DOCUMENT, AND I FULLY UNDERSTAND ITS CONTENT. I AM AWARE THAT THIS IS A RELEASE OF LIABILITY AND A CONTRACT AND I SIGN IT ON MY OWN FREE WILL.`}
                    </WaiverContent>
                    <TextField
                        label="Print Participant’s Name"
                        name="waiverName"
                        value={formData.waiverName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                    />
                    <Typography variant="body2">
                        {`PARENT/GUARDIAN WAIVER FOR MINORS (under 18 years old) The Undersigned parent and or natural guardian does hereby represent that he/she is, in fact, acting in such capacity, has consented to his/her child or ward’s participation in the Mars Reel Academy, and has agreed individually and on behalf of the child or ward, to the terms of the accident waiver and release of liability set forth above. The undersigned parent or guardian further agrees to save and hold harmless and indemnify each and all parties referred to above from all liability, loss, cost, claim, or damage whatsoever which may be imposed upon said parties because of any defect on lack of such capacity to so act and release said parties on behalf of the minor and the parents or legal guardian.`}
                    </Typography>
                    <TextField
                        label="Authorized Signature Name (if under 18 years)"
                        name="authorizedSignatureName"
                        value={formData.authorizedSignatureName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        variant="filled"
                    />
                    <Box>
                        <Typography variant="body2">Authorized Signature:</Typography>
                        <SignatureCanvas
                            ref={handleRef}
                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                            backgroundColor="rgba(255, 255, 255, 0)"
                            onEnd={handleSignatureEnd}
                        />
                        <Button variant="outlined" onClick={() => signaturePadRef.current && signaturePadRef.current.clear()}>Clear</Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseWaiverDialog} style={{ color: '#12c099' }}>
                        Cancel
                    </Button>
                    <Button onClick={handleWaiverConsent} style={{ color: '#12c099' }}>
                        Agree and Sign
                    </Button>
                </DialogActions>
            </Dialog>
        </Root>
    );
};

export default CreateAccount;

