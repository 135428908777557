import React from 'react';
import { Container, Typography } from '@mui/material';

const PaymentIssues = () => {
    return (
        <Container style={{ paddingTop: '6rem', paddingBottom: '2rem' }}>
            <Typography variant="h4">Payment Issues</Typography>
            <Typography variant="body1" paragraph>
                This page will help you with payment issues such as updating payment methods, understanding billing cycles, and resolving other payment-related problems.
            </Typography>
        </Container>
    );
};

export default PaymentIssues;
