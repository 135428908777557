import React, { useState } from 'react';
import { Container, Typography, Card, CardContent, Grid, TextField, Button, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    paddingTop: '6rem',
    paddingBottom: '2rem',
    minHeight: '100vh',
});

const StyledCard = styled(Card)({
    marginBottom: '1rem',
});

const TechnicalSupport = () => {
    const [form, setForm] = useState({ name: '', email: '', message: '' });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/email/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(form),
            });

            if (response.ok) {
                setIsSubmitted(true);
                setForm({ name: '', email: '', message: '' });
            } else {
                const data = await response.json();
                setErrorMessage(data.error || 'Failed to send message.');
            }
        } catch (error) {
            console.error('Error submitting form', error);
            setErrorMessage('An error occurred while submitting the form.');
        }
    };

    return (
        <StyledContainer maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Technical Support
            </Typography>
            <Typography variant="body1" paragraph>
                If you're experiencing technical difficulties, here are some common issues and solutions to help you get back on track.
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">Login Issues</Typography>
                            <Typography variant="body2">
                                Ensure your username and password are correct. If the issue persists, reset your password or try using a different browser.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">Payment Problems</Typography>
                            <Typography variant="body2">
                                Verify that your billing information is correct and your payment method is up-to-date. If you see a charge issue, contact support for assistance.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">Account Verification Issues</Typography>
                            <Typography variant="body2">
                                If you're not receiving verification emails, check your spam/junk folder or verify that you entered the correct email address.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">Mobile App Performance</Typography>
                            <Typography variant="body2">
                                If the mobile app is running slowly, try restarting the app or clearing the cache. Ensure that you have the latest version installed.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">Browser Compatibility</Typography>
                            <Typography variant="body2">
                                For the best experience, use the latest version of Chrome, Firefox, or Safari. If you're having trouble, try switching to another browser.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">Security Concerns</Typography>
                            <Typography variant="body2">
                                If you suspect unauthorized access to your account, change your password immediately and enable two-factor authentication for added security.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
            </Grid>

            <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
                Still need help?
            </Typography>
            <Typography variant="body1" paragraph>
                If your issue isn't listed above, please fill out the form below to contact our support team, and we'll get back to you as soon as possible.
            </Typography>

            {isSubmitted ? (
                <Typography variant="h6" color="primary">
                    Your message has been sent successfully. We'll get back to you soon!
                </Typography>
            ) : (
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Your Name"
                        name="name"
                        fullWidth
                        margin="normal"
                        value={form.name}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        fullWidth
                        margin="normal"
                        value={form.email}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Describe Your Issue"
                        name="message"
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                        value={form.message}
                        onChange={handleInputChange}
                        required
                    />
                    {errorMessage && (
                        <Typography color="error" variant="body2">
                            {errorMessage}
                        </Typography>
                    )}
                    <Box mt={2}>
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </Box>
                </form>
            )}
        </StyledContainer>
    );
};

export default TechnicalSupport;
