import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import NavigationBar from './NavigationBar'; // Make sure to import NavigationBar
import axios from 'axios';

const Root = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#141414',
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    zIndex: 2,
    marginTop: '4rem',
});

const FreeSessionText = styled(Typography)({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#12c099',
    marginBottom: '1rem',
    zIndex: 2,
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    margin: '0.5rem',
    padding: '1rem 2rem',
    display: 'flex',
    alignItems: 'center',
});

const IconBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
});

const StartTraining = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const location = useLocation();
    const { user } = location.state || { user: null }; // Get user details from previous state

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });

        // Cleanup the subscription on component unmount
        return () => unsubscribe();
    }, []);

    const triggerCompleteRegistrationEvent = async () => {
        const pixelId = '586371507254917'; // Replace with your Pixel ID
        const accessToken =
            'EAAIckjdekZBgBO3vas3Fv4AX1ZAc6qpPdD4cXqcmv7x6gG5Y4eQAxdJ3YEe38SAIZCdpGKcfggKpxEmL8fwcXLowYA0YgIBeUdIWkItXnwPxgWD1EZCQpQBCHZByPwEZCElZAJSAPOvG9Vo5kz3JiCN3ZAFxcSZCSKzTPZBJMX0kQDN0A41hZBCrxzgsCBhJKgu5SMb9QZDZD';
        const apiUrl = `https://graph.facebook.com/v21.0/${pixelId}/events`;

        const eventData = {
            data: [
                {
                    event_name: 'CompleteRegistration',
                    event_time: Math.floor(Date.now() / 1000), // Current Unix timestamp
                    event_source_url: window.location.href, // Current page URL
                    action_source: 'website',
                    user_data: {
                        em: user?.email || '', // Email
                        ph: user?.phone || '', // Phone
                        fn: user?.firstName?.toLowerCase() || '', // First Name
                        ln: user?.lastName?.toLowerCase() || '', // Last Name
                        ge: user?.gender?.toLowerCase() || '', // Gender
                        db: user?.birthday || '', // Date of Birth
                        ct: user?.city?.toLowerCase() || '', // City
                        st: user?.state?.toLowerCase() || '', // State
                        zp: user?.zipCode || '', // Zip Code
                        country: user?.country?.toLowerCase() || '', // Country
                        client_user_agent: navigator.userAgent, // Browser's User-Agent
                    },
                },
            ],
        };

        try {
            const response = await axios.post(apiUrl, eventData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log('CompleteRegistration Event Sent:', response.data);
        } catch (error) {
            console.error('Failed to send CompleteRegistration event:', error);
            // Graceful handling: Log the error but do not interrupt the user flow
        }
    };

    useEffect(() => {
        if (user) {
            triggerCompleteRegistrationEvent(); // Trigger the Facebook event
        }
    }, [user]);

    const handleDashboard = () => {
        if (isAuthenticated) {
            navigate('/dashboard');
        } else {
            navigate('/login');
        }
    };

    return (
        <Root>
            <NavigationBar isLoggedIn={true} handleLogout={() => {}} />
            <StyledContainer maxWidth="md">
                <Typography variant="h4" gutterBottom>
                    Welcome to Mars Reel Academy!
                </Typography>
                <FreeSessionText>Your account has been set up successfully. You can now start training!</FreeSessionText>
                <IconBox>
                    <ActionButton
                        href="https://apps.apple.com/us/app/mars-reel-academy/id6680150371"
                        target="_blank"
                        startIcon={<AppleIcon />}
                    >
                        Download for iOS
                    </ActionButton>
                    <ActionButton
                        href="https://play.google.com/store/apps/details?id=com.marsdeyo.marsreelacademy"
                        target="_blank"
                        startIcon={<AndroidIcon />}
                    >
                        Download for Android
                    </ActionButton>
                </IconBox>
                <IconBox>
                    <ActionButton onClick={handleDashboard}>
                        Go to Dashboard
                    </ActionButton>
                </IconBox>
            </StyledContainer>
        </Root>
    );
};

export default StartTraining;
