import React from 'react';
import { Container, Typography, Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    paddingTop: '6rem',
    paddingBottom: '2rem',
    minHeight: '100vh',
});

const StyledCard = styled(Card)({
    marginBottom: '1rem',
});

const UsingCredits = () => {
    return (
        <StyledContainer maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Using Credits
            </Typography>
            <Typography variant="body1" paragraph>
                Learn how to earn and use credits for accessing various activities and events.
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">How to Earn Credits</Typography>
                            <Typography variant="body2">
                                You can earn credits by participating in certain activities, inviting friends, or reaching milestones. Each credit can be used to access premium content, activities, or events.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>

                <Grid item xs={12}>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h6">How to Use Credits</Typography>
                            <Typography variant="body2">
                                Once you've earned credits, you can use them to redeem rewards such as event tickets, exclusive content, or discounts on subscriptions.
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};

export default UsingCredits;
