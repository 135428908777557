import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    paddingTop: '6rem',
    paddingBottom: '2rem',
    minHeight: '100vh',
});

const ContactUs = () => {
    const [form, setForm] = useState({ name: '', email: '', message: '' });
    const [isSubmitted, setIsSubmitted] = useState(false);  // Track submission status
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/email/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(form),
            });

            if (response.ok) {
                setIsSubmitted(true);
                setForm({ name: '', email: '', message: '' });
            } else {
                const data = await response.json();
                setErrorMessage(data.error || 'Failed to send message.');
            }
        } catch (error) {
            console.error('Error submitting form', error);
            setErrorMessage('An error occurred while submitting the form.');
        }
    };

    return (
        <StyledContainer maxWidth="md">
            <Typography variant="h4" gutterBottom>
                Contact Us
            </Typography>
            {isSubmitted ? (
                <Typography variant="h6" color="primary">
                    Your message has been sent successfully. We'll get back to you soon!
                </Typography>
            ) : (
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Name"
                        name="name"
                        fullWidth
                        margin="normal"
                        value={form.name}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        fullWidth
                        margin="normal"
                        value={form.email}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Message"
                        name="message"
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                        value={form.message}
                        onChange={handleInputChange}
                        required
                    />
                    {errorMessage && (
                        <Typography color="error" variant="body2">
                            {errorMessage}
                        </Typography>
                    )}
                    <Box mt={2}>
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </Box>
                </form>
            )}
            <Box mt={8} textAlign="center">
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp.
                </Typography>
            </Box>
        </StyledContainer>
    );
};

export default ContactUs;
